<template>
    <!-- Modal Basic -->
    <div class="modal fade modalbox" id="viewWalletAddress" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{wallet.id}} Addresses</h5>
                    <a href="javascript:void(0);" data-bs-dismiss="modal">Close</a>
                </div>
                <div class="modal-body">
                    <template v-if="wallet.data.addresses">
                        <div v-if="wallet.data.addresses.length > 0">
                            <p class="text text-dark" v-for="(address, idx) in wallet.data.addresses" :key="idx">
                                {{address}}
                                <span class="float-end">
                                    <ion-icon @click="deleteAddress(address)" class="text-danger fw-20" name="trash-outline"></ion-icon>
                                </span>
                            </p>
                        </div>
                        <p class="text-center fw-bold" v-else>
                            No address has been added yet
                        </p>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <!-- * Modal Basic -->
</template>

<script>
import {firestore, wallet_addresses_store} from "../../../configs/firebase";

export default {
    name: "viewWalletAddress",
    data(){
        return {

        }
    },
    props: {
        wallet: {
            type: Object,
            required: true,
            default: ()=>{
                return {id: '', data: {}}
            }
        }
    },
    methods: {
        deleteAddress(address){

            wallet_addresses_store.doc(this.wallet.id).update({
                addresses: firestore.FieldValue.arrayRemove(address)
            }).then(()=>{
                this.$store.dispatch('notification/display_noti', {
                    message: 'Address deleted',
                    timeout: 1500
                });
                $('#viewWalletAddress').modal('hide')
            })
        }
    }
}
</script>

<style scoped>

</style>