<template>
    <div id="appCapsule">
        <div class="section wallet-card-section">
            <div class="card">
                <div class="col-12 text-end text-primary py-2">
                    <router-link :to="{name: 'AddWallet'}">
                        <ion-icon name="add-circle-outline"></ion-icon> Add
                    </router-link>
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Wallet</th>
                            <th scope="col">No. of Addresses</th>
                            <th scope="col" class="text-end">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-if="wallets.length > 0">
                            <tr v-for="(wallet, idx) in wallets" :key="idx">
                                <th scope="row">{{idx+1}}</th>
                                <td>{{wallet.id}}</td>
                                <td>{{wallet.data.addresses.length}}</td>
                                <td>
                                    <div class="row justify-content-around">
                                        <ion-icon @click="active_data=wallet" data-bs-toggle="modal" data-bs-target="#viewWalletAddress" class="text-primary fw-20" name="eye-outline"></ion-icon>
                                        <ion-icon @click="deleteWallet(wallet)" class="text-danger fw-20" name="trash-outline"></ion-icon>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td class="text-center text-primary" colspan="4">
                                    <h5>Empty wallet history</h5>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
        <view-wallet-address :wallet="active_data"/>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import viewWalletAddress from "../../../components/admin/modals/viewWalletAddress";

export default {
    name: "wallet",
    data(){
        return {
            active_data: {id: '', data: {}}
        }
    },
    computed: {
      ...mapGetters('wallet', ['wallets'])
    },
    methods: {
        // eslint-disable-next-line no-unused-vars
      deleteWallet(wallet){
          this.$store.dispatch('notification/display_noti', {
              message: 'Continue with this operation?',
              context: 'icon',
              buttons: [
                  {
                      name: 'Confirm',
                      callback: async ()=>{
                          this.$store.dispatch('notification/closeNoti', 'noti-ios-buttons');
                          let response = await this.$store.dispatch('wallet/deleteWallet', wallet);
                          if(response.status){
                              return this.$store.dispatch('notification/display_noti', {
                                  message: 'Wallet deleted',
                                  timeout: 1500
                              });
                          }else{
                              return this.$store.dispatch('notification/display_noti', {
                                  message: response.message,
                              });
                          }
                      }
                  },
                  {
                      name: 'Cancel',
                      callback: ()=>this.$store.dispatch('notification/closeNoti', 'noti-ios-buttons')
                  }
              ]
          });
      }
    },
    components: {
      viewWalletAddress
    },
    mounted() {
        this.$store.dispatch('wallet/fetchWallets')
    }
}
</script>

<style scoped>

</style>